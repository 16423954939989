.partial-chat-view {
	// rem out - Andrew
	// width: 55%;
	// height: 100%;
	// position: absolute;
	// right: 0;
	// top: 0;
	width: 100%;
	display: flex;
	flex-direction: column;
	box-sizing: border-box;
	//box-shadow: inset 0 7px 9px -7px rgba(0,0,0,0.4);
	background-color: white;
	// overflow-y: scroll;
	// rem out - Andrew 
	// overflow-y: hidden;

	@media screen and (max-width: $breakpoint-l) {
        width: 100%;
        position: relative;
    }

    .container-chat-box {
    	position: relative;
		display:flex;
		flex-direction: column;
		align-items: center;
    	z-index: 1;

		//@media screen and (max-width: $breakpoint-sm) {
		//	// height: calc(100% - 110px);
		//}
		// rem out - Andrew height: 100%;
		// rem out - Andrew overflow-y: hidden;

    	.sprite-left,
    	.sprite-right {
    		overflow: hidden;
    		background: $color-secondary;
    		pointer-events: none;
    		position: absolute;
    		top: -1px;
    		width: 49px;
    		height: 49px;
    		z-index: 4;
    		display: none;

    	 //	@media screen and (max-width: $breakpoint-l) {
		 //       display: inline-block;
		 //   }
    	}

		.sprite-left-border {
			content: '';
			background: white;
    		width: 96px;
    		height: 96px;
			border-radius: 100%;
			z-index: 5;
			position: absolute;
			top: 0;
			left: 0;
			// rem out - Andrew - hide for now
			display: none;
		}

		.sprite-right-border {
			content: '';
			background: white;
    		width: 96px;
    		height: 96px;
			border-radius: 100%;
			z-index: 5;
			position: absolute;
			top: 0;
			right: 0;
			// rem out - Andrew - hide for now
			display: none;
		}

    	.sprite-left {
    		left: -1px;
			// rem out - Andrew - hide for now
			display: none;
    	}

    	.sprite-right {
    		right: -1px;
			// rem out - Andrew - hide for now
			display: none;
    	}
    }

    .chat-box {
		// rem out - Andrew
    	// padding: 0 60px; 
    	position: relative;
		// rem out - Andrew
		//	height: 100%;
		//	max-height: calc(100% - $header-height);
		//	overflow-y: auto;
		z-index: 6;
		// rem out - Andrew
		// padding-right: 25%; // Limit size of chat container. padding instead of width to handle scroll
		max-width: 700px;
		padding-bottom: 50px; // Header padding bot\
		padding-top: 25px;
		p{
			font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
		}
		@media screen and (max-width: $breakpoint-sm) {
			padding: 10px 40px 100px 40px; 
			max-width:500px;
			&#chat-box .component-image {
				margin-left: 0 !important;
			}
		}

    	.component-chat-message, .component-image {
    		width: 100%;
    		max-width: 360px;
    		// margin: 0 auto 16px 80px !important;
			z-index: 6;

			@media screen and (max-width: $breakpoint-sm) {
				width: unset;
				max-width: unset;
			    margin-left: 0px !important;
				margin-bottom: 8px !important;
			}

    		svg {
    			max-height: 120px;
    		}

    		.text {
    			line-height: 1.5rem;
				text-align: left;

				a{
					color: #725194;
					font-size: 1rem;
					padding: 0 6px;
				}

    		}
    	}

		.component-image {
			margin-bottom: 40px !important;

			@media screen and (max-width: $breakpoint-sm) {
				margin-bottom: 24px !important;
			}
		}

    	.component-multi-choice {
    		.component-button {
    			max-width: 300px;

				@media screen and (max-width: $breakpoint-sm) {
					margin-left: auto;
					margin-right: auto;
				}
    		}
    	}

    	.component-button:not(.yesno):not(.split) {
    		// min-width: 240px;
    		// max-width: max-content;
    		// margin: 0 auto;
			padding-left: 2rem;
			padding-right: 2rem;
    	}

    	.form-control.to-input {
    		//max-width: 360px;
    		margin-left: 0px;
			//max-width: 306px;
			width: 100%;

			@media screen and (max-width: $breakpoint-sm) {
				margin-left: 0px;
			}
    	}

		.chat-heading {
			margin: 16px 0 32px 0;

			@media screen and (max-width: $breakpoint-sm) {
				font-size: 1.25rem;
				margin-top: 0;
			}

			@media screen and (min-width: $breakpoint-sm) {
				text-align: left;

				// @include margin-user-chat-input-left;
			}
		}

		.form-control.to-input {
			width: unset;
		}
    }
}