.component-autocomplete-input {
    position: relative;
    input {
        background-image: url('data:image/svg+xml,<svg width="15" height="10" viewBox="0 0 15 10" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M0.919433 1L7.41943 8L13.9194 1" stroke="black" stroke-width="2"/></svg>');
        background-position: right;
        background-repeat: no-repeat;
        background-position-x: calc(100% - 10px);
        padding-right: 30px;
        min-width: 240px;
    }

    &.active {
        input {
            background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="15" height="10" viewBox="0 0 15 10" fill="none"  xmlns:xlink="http://www.w3.org/1999/xlink" preserveAspectRatio="xMinYMin meet"><rect id="svgEditorBackground" x="0" y="0" width="15" height="10" style="fill:none;stroke:none"/><path d="M0.275071,1.198265L6.775068,8.198265L13.275038,1.198265" stroke="black" stroke-width="2" transform="matrix(-0.984733 0 0 -0.984733 13.3399 9.2638)"/></svg>');
        }
    }
    .no-suggestions {
        text-align: left;
        color: red;
    }
    .suggestions {
        border: 1px solid $color-slate;
        border-top-width: 0;
        list-style: none;
        margin-top: 0;
        max-height: 143px;
        overflow-y: auto;
        padding-left: 0;

        width: unset;
        max-width: 306px;
        margin-left: 60px;

        position: absolute;
        min-width: 240px;
        padding: unset;
        z-index:10;
    }

    .suggestions li {
        padding: 0.5rem;
        // background-color:blue;
    }

    .suggestion-active,
    .suggestions li:hover {
        background-color: $color-primary-2-hover;
        color: $color-white;
        cursor: pointer;
        font-weight: 700;
    }

    .suggestions li:not(:last-of-type) {
        border-bottom: 1px solid $color-slate;
    }


    &.nested {
        margin: 0 !important;

        input {
            margin: 0 !important;
            max-width: 306px !important;
            width: 100% !important;
            background-image: url('data:image/svg+xml,<svg width="15" height="10" viewBox="0 0 15 10" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M0.919433 1L7.41943 8L13.9194 1" stroke="black" stroke-width="2"/></svg>');
            background-position: right;
            background-repeat: no-repeat;
            background-position-x: calc(100% - 10px);
            padding-right: 30px;
            min-width: 240px;
        }

        &.active {
            input {
                background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="15" height="10" viewBox="0 0 15 10" fill="none"  xmlns:xlink="http://www.w3.org/1999/xlink" preserveAspectRatio="xMinYMin meet"><rect id="svgEditorBackground" x="0" y="0" width="15" height="10" style="fill:none;stroke:none"/><path d="M0.275071,1.198265L6.775068,8.198265L13.275038,1.198265" stroke="black" stroke-width="2" transform="matrix(-0.984733 0 0 -0.984733 13.3399 9.2638)"/></svg>');
            }
        }

        .suggestions {
            margin-left: 0 !important;
            max-width: 306px !important;
            width: 100% !important;
        }
    }
    .text-loading{
        position: absolute;
        top: 2px;
        left: 242px;
    }
}