.component-chat-avatar {
    height: 60px;
    width: 60px;
    z-index: 6;
    // rem out - Andrew - set to display none for now
    display:none;

    &.small {
        height: 36px;
        width: 36px;
    }

    @media screen and (max-width: $breakpoint-sm) {
        height: 36px;
        width: 36px;
    }

    svg {
        .fill-primary {
            fill: $color-primary;
        }
        .fill-primary-2 {
            fill: $color-primary-2;
        }
        .fill-secondary {
            fill: $color-secondary;
        }
        .fill-primary-3 {
            fill: $color-primary-3;
        }
    }
}