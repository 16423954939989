.component-dynamic-input {
	@media screen and (min-width: 768px){
		padding-left:80px;
	}
	.form-control {
		transition: background 250ms;

		
		&[disabled="true"] {
			background: $color-slate;
		}
	}
}