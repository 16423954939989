.component-risk-factor{
    padding: 0 50px 24px;
    
    .text-below-meter{
        p{
            font-family: 'MikadoBold';
        }
            
        display: flex;
        justify-content: space-between;

        .left-text {
            text-align: left;
        }

        .right-text {
            text-align: right;
        }
    }
    .main-bar{
        border-radius: 25px;
        height: 30px;
        position: relative;
        align-items: center;
        margin-top: 20px;
        position: relative;
        //box-shadow: inset 1px 2px 8px -5px;
        background-color: white;
        // transition: width 1s ease-in-out;

    }
    .first-br{
        background-color: #7fc68d; 
        height: 100%;
    }
    .progress-br {
        border-radius: 25px;
        align-self: flex-end;
        box-shadow: inset 2px 3px 6px -5px;
        height: 100%;
        border: none;
        transition-duration: 2s;
        transition-timing-function: cubic-bezier(0.64, 0.57, 0.67, 1.53);
        position: absolute;

        &.inner-progress,
        &.inner-progress-low {        
            background-color: $color-results-1;
            z-index: 5;
        }
        &.inner-progress-2,
        &.inner-progress-medium-low,
        &.inner-progress-mediumLow {
            background-color: $color-results-2;
            z-index: 4;
        }
        &.inner-progress-3,
        &.inner-progress-medium {
            background-color: $color-results-3;
            z-index: 3;
        }
        &.inner-progress-4,
        &.inner-progress-medium-high,
        &.inner-progress-mediumHigh {
            background-color: $color-results-4;
            z-index: 2;
        }
        &.inner-progress-5,
        &.inner-progress-high {
            background-color: $color-results-5;
            z-index: 1;
        }
    }
    .absolu {
        position: absolute;
        top: 0%;
        left:0;
    }
    .header {
        position: absolute;
        left: 50%;
        transform: translate(-50%, 0);
        color: $color-white;
        font-size: 1rem;
        line-height: 1.25rem;
    }
    .alignleft {
        float: left;
    }
    .alignright {
        float: right;
    }
    .dotted{
        margin-top: -15px;
        height: 30px;
        border-right: 1px dashed rgba(0, 0, 0, 0.5);
        border-left: 1px dashed rgba(0, 0, 0, 0.5);
    }
    .chart-meter{
        float: right;
        z-index: 999;
    }
    .meter-trans{
        background-color: transparent;
        z-index: 999;
        // transition: all 0.8s ease;
        height: 100%;
        transition-timing-function: cubic-bezier(0.64, 0.57, 0.67, 1.53);
        transition-duration: 1.4s;
    }
    .for-ov-he{
        border-radius: 25px;
        position: relative;
        overflow: hidden;
        height: 100%;
    }
    
}