.component-result-header{
    .para-1{
        padding: 26px 30px 18px 30px;
        font-family: LucidaGrande;
        font-size: 1rem;
        &.background-primary-2{
            background-color: $color-secondary-2;
        }
        &.background-color-secondary{
            background-color: $color-secondary;
        }
    }   
    .bold-c{
        font-family: 'Mikadobold';
    }
}