.micro-message {
    display: flex;
    justify-content: center;
    width: 100%;

    .component-chat-avatar {
        margin-right: 20px;
    }

    .text {
        font-style:     italic;
        font-size:      1rem;
        line-height:    1.6rem;
        text-align:     left;
    }
}

.component-chat-message {
    padding: 16px;
    border-radius: 0px 16px 16px 16px;
    position: relative;
    width: 80%;
    margin-bottom: 10px;
    
    img {
        max-width:100%;
        max-height:160px;            
    }

    video {
        max-width:100%;
    }

    .text {
        i, span, strong, b, em, a {
            padding-right: 6px;
        }
    }

    .message-bobble {
        overflow: hidden;
        position: absolute;
        background-color: transparent;
        height: 16px;
        width: 16px;
        top: 0;
        left: -16px;

        .message-bobble-inner {
            position: absolute;
            transform: rotate(45deg);
            height: 16px;
            width: 16px;
            border-bottom-left-radius: 2px;
            top: -6px;
            left: 8px;
        }
    }
} 
