input[type="radio"].radio_input:checked+label .primary,
input[type="checkbox"].radio_input:checked+label .primary {
    background-color: $color-secondary;
}

.component-button {
    border: unset;
    text-decoration: none;
    display: flex;
    text-align: center;
    align-items: center;
    justify-content: center;

    &.regular {
        min-height: 3rem;
        // border-radius: 25px;
        // padding: 12px 48px;
        display: flex;
        align-items: center;
        // white-space: nowrap;

        &.yesno {
            height: 3rem;
            padding: 6px 55px;
        }
    }

    &.circle {
        width: 32px;
        height: 32px;
        background-color: $color-white;
        border-radius: 50%;
        padding:0px;

        &.arrow {
            background-image: $arrow-url-black;
            background-size:45%;         
            background-repeat: no-repeat;
            background-position: center;
            background-position-x: 6px;

            svg path {
                stroke: $color-juicy-grape-100;
            }
            & > svg.arrow-url-black{
                fill:$color-juicy-grape-100;
            }

            &.right {
                transform: rotate(180deg);
            }
        }
    }

    &.primary {
        background-color: $color-primary-3; //$color-primary-2
        color: $color-primary-2;

        &:hover {
           // background-color: $color-primary-3-hover; //$color-primary-2-hover
           // color: $color-secondary;  // doesnt' so anything as text is in a child element.
            & .inner-text { color:teal;}
        }

        &.selected {
            background-color: $color-primary-3-hover;
        }

        &.disabled {
            background-color: $color-silver;
            cursor: not-allowed;
        }
    }

    &.secondary {
        background-color: $color-secondary;

        &:hover {
            background-color: $color-secondary-hover;
        }
    }

    &.nav {
        width: 35px;
        height: 35px;
        border-radius: 50%;
 
        &.small {
            width: 32px;
            height: 32px;
        }

        &.arrow {
            background-image: $arrow-url-black;
            background-size: 45%;
            background-repeat: no-repeat;
            background-position: center;
            background-position-x: 6px;
        }

        &.invert {
            background-image: $arrow-url-white;
        }

        &.right {
            transform: rotate(90deg);
        }

        &.down {
            transform: rotate(180deg);
        }

        &.left {
            transform: rotate(270deg);
        }
    }

    span.text-block {
        &.hover-primary {
            &:hover {
                background-color: $color-primary;
                background-image: $arrow-url-white;
            }
        }

        &.hover-secondary {
            &:hover {
                background-color: $color-secondary;
                background-image: $arrow-url-white;
            }
        }
    }

    &.split {
        width: 100%;
        border-radius: 16px;
        filter: drop-shadow(0px 4px 12px rgba(172, 172, 172, 0.25));
        justify-content: flex-start;

        .inner-text {
            padding: 10px 25px;
            font-size: 1rem;
            line-height: 1;
        }

        .image-wrapper {
            height: 75px;
            min-width: 100px;
            position: relative;

            &.no-img {
                width: 0;
                min-width: 0;

                .background {
                    width: 0;
                }
            }

            .background {
                position: absolute;
                top: 0;
                bottom: 0;
                left: 0;
                width: 100px;
                z-index: 2;
                background-color: $color-white;
                border-top-left-radius: 16px;
                border-bottom-left-radius: 16px;
            }

            .image {
                height: 100%;
                width: 100%;
                background-repeat: no-repeat;
                background-position: center;
                position: relative;
                z-index: 3;
                display: flex;
                justify-content: center;
                align-items: center;

                img {
                    max-height: 62.5px;
                }

                &.clock {
                    background-image: url('../assets/icon-clock.svg');
                }
            }
        }
    }

    .inner-text {
        pointer-events: none;
        text-transform: uppercase;
        font-size: $font-size-button;
        color: $color-primary-2;
        font-family: MikadoBold;
        font-weight: $font-weight-bold;
        letter-spacing: 1px;
        width: 100%;
    }

    &.disabled .inner-text {
        color: $color-slate;
        cursor: not-allowed;
    }

    &:hover {
        cursor: pointer;
        color: $color-secondary
    }

    &.full-width {
        padding: 0 20px;
    }

    &.hide {
        display: none;
    }

    // &.disabled {
    //     pointer-events: none;
    // }

    @media only screen and (max-width: $breakpoint-xs) {
        &.full-width {
            padding: 12px;
            max-width: unset !important;
        }
    }
}