.component-image {
    margin-bottom: 40px;    
    
    @media screen and (max-width: $breakpoint-sm) {
        margin-bottom: 24px;    
    }

    img {
        height: 100%;
        width: 100%; 
        max-height: 175px;
        object-fit: contain;
    }
    img.atsi_hc_header {
        height:auto;
        max-width: 600px;
        aspect-ratio: 598/706;
        max-height:50%;
        object-fit:contain;
    }
}