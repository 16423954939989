// Adding styles for the wordpress embed site
main.content {
    .page-content {
        width: 100% !important;
        max-width: 100% !important;
    }
}

:host {
    all: initial;
}

#mh4l-root:host {
    all: initial;
}

#mh4l-root.embed .partial-desktop-header {
    display: none !important;
}

.App {
    font-family: LucidaGrande;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.main-content {
    background-color: white;
    border-top-left-radius: 25px;
    border-top-right-radius: 25px;
}

p {
    font-size:1rem;
    line-height: 1.6rem;
}

a {
    font-weight: $font-weight-bold;
    color: $color-primary;
    font-size: $font-size-anchor;
    text-align: center;
    line-height: 13px;
    cursor: pointer;

    &:hover {
        color: $color-primary-2-hover!important;
    }
}

.text-block {
    text-align: center;
}

.bg-white {
    background-color: $color-white;
}

.bg-pattern {
    // background: url('../assets/MH4L_Background_Pattern.svg');
    background-color: $color-secondary;
}

.bg-white {
    background-color: white;
    &.header {
        font-size: $font-size-header;
        font-family: MikadoBold;
        font-weight: $font-weight-bold;
        line-height: 25px;
    }
}

.bg-primary {
    background-color: $color-primary !important;
}

.bg-primary-2 {
    background-color: $color-primary-2;
}

.bg-primary-3 {
    background-color: $color-primary-3;
}

.bg-primary-4 {
    background-color: $color-primary-4;
}

.bg-primary-5 {
    background-color: $color-primary-5;
}

.bg-secondary {
    background-color: $color-secondary;
}

.bg-secondary-2 {
    background-color: $color-secondary-2;
}

.bg-light-blue {
    background-color: $color-light-blue;
}

.bg-light-purp {
    background-color: $color-light-purp;
}

@mixin text-header {
    text-align: center;
    font-size: $font-size-header;
    font-family: MikadoBold;
    font-weight: $font-weight-bold;
}

@mixin text-general {
    text-align: center;
    font-size: $font-size-regular;
}

.mh4l-logo {
    // background-image: url("../assets/logo.svg");        
    background-repeat: no-repeat;
    background-position: center;

    &.black {
        background-image: url("../assets/logo-black.svg");        
    }
}

.text-white {
    color: $color-white;
} 

.container-primary {
    // position: relative;
    // added display grid and grid template - Andrew
    display: flex;
    max-width:800px;
    // grid-template-columns: 1fr 1fr;
    // rem out - Andrew
    // overflow-y: hidden;
    // height: calc(100vh - 90px); //height of header
    // rem out - Andrew
    // height: 100%;

    @media screen and (max-width: $breakpoint-sm) {
        // height: 100vh;
    }
    // rem out - Andrew
   // &.scrollable {
       // height: 100vh;
       // min-height: 100%;
   // }
}

.margin-user-chat-input {
    margin: 0 60px 0 auto;
}
/* rem out - Andrew
@mixin margin-user-chat-input-left {
    margin-left: 60px;

    @media screen and (min-width: $breakpoint-sm) {
        margin-left: 80px
    }
}
 */
.last-button {
    margin: 32px 0 0 60px;

    //&.Single-Select, &.Multi-Select, &.InformationOnly, &.dynamic-input-button, &.Single-Select-Dropdown, &.Yes\/No, &.BloodPressure {
        // margin-left: auto;
        // margin-right: auto;
    // }

//    @media screen and (min-width: $breakpoint-sm) {
 //       margin-left: 80px
  //  }

    &.component-dynamic-input {
        margin-top: 16px;
    }
}

.modal {
    background: rgba(0,0,0,0.1);
    // display: block !important;
}

.isLoading {
    width:100vw;
    width:100svw;
    // width:100dvh;
    height:100vh;
    height:100svh;
    height:100dvh;
    position:absolute;
    z-index: 10;
    margin-left:auto;
    margin-right:auto;
    padding-top:25vh;
    padding-left:10%;
    padding-right:10%;
    background-color:rgba(255,255,255,1);
    &.initial {
        padding-top:0px;
    }
    & > svg {
      max-width:600px;
    }
}


.loaderpath {
    stroke-dasharray: 2000;
    animation: loaderanim 5s linear reverse infinite;
    stroke: #FFDE3E;
}
    
@keyframes loaderanim {
    to {
        stroke-dashoffset: 8000;
    }
}

.loading {
    display: inline-block;
    width: 80px;
    height: 80px;

    &.small {
        width: 40px;
        height: 40px;

        &:after {
            width: 32px;
            height: 32px;
            border-width: 3px;
        }
    }

    &:after {
        content: " ";
        display: block;
        width: 64px;
        height: 64px;
        margin: 8px;
        border-radius: 50%;
        border: 6px solid $color-primary;
        border-color: $color-primary-2 transparent $color-primary-2 transparent;
        animation: loading-animation 1.2s linear infinite;
    }
}

@keyframes loading-animation {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

.text {
    &.text-error,
    &.text-success, 
    &.text-loading,
    &.text-validate {
        text-align: left;
       // margin-left: 80px;
       min-height: 1.25rem;
       line-height: 1.25rem; // override text class line-height.
       
        // rem out - Andrew
        // @media screen and (max-width: $breakpoint-sm) {
        //     margin-left: 60px;
        // }

        &.text-error-results {
            margin-left: 0;
            height: unset;
        }
    }

    &.text-error {
        color: $color-results-4 !important;
    }

    &.text-success {
        color: $color-results-1 !important;
    }

    &.text-loading {
        color: $color-primary-2 !important;
    }

    &.text-loading {
        .loading {
            width: 20px !important;
            height: 20px !important;

            &:after {
                width: 16px !important;
                height: 16px !important;
                border-width: 3px !important;
            }
        }
    }

    &.hide {display: none;}
    &.text-error.hide {
        display: block;
        opacity: 0; // use opacity 0 to avoid pushing page content when we display it.
    } 
}

.last-button {
    margin-bottom: 16px;
}
img.icon-info {
    width:1rem;
}
div.questionText {
    position:relative;
}

div.info{
    float:right;
}

img.info{
    width:20px;
}
.tooltip {
    position: relative;
    display: inline-block;
    float:right;
    opacity: 1;
}
  
.tooltip .tooltiptext {
    visibility: hidden;
    max-width: 300px;
    margin-right: -300px;
    background-color: #555;
    color: #fff;
    text-align: center;
    border-radius: 6px;
    padding: 5px;
    position: absolute;
    z-index: 1;
    bottom: 10px;
    right:285px;
    opacity: 0;
    transition: opacity 0.3s;
}
  
.tooltip .tooltiptext::after {
    content: "";
    position: absolute;
    top: 100%;
    right: 20px;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: #555 transparent transparent transparent;
}

.tooltip:hover .tooltiptext {
    visibility: visible;
    opacity: 1;
}

.tooltip:active .tooltiptext {
    visibility: visible;
    opacity: 1;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

input[type="number"] {
    -moz-appearance: textfield;
}