.component-result-tab {
    // position: relative;

    // @media only screen and (max-width: $breakpoint-l) {
    //     margin-left: -40px;
    //     margin-right: -40px;    
    //     padding:50px 24px 0px;
    // }

    @media only screen and (min-width: $breakpoint-sm) {
        .heading {
            font-size: 20px;
            line-height: 26px;
            margin-bottom: 32px;
        }
    }

    .heading {
        margin-bottom: 24px;
    }

    .tab-content {
        border-bottom-left-radius: 10px;
        border-bottom-right-radius: 10px;

        .tab-content-upper-section {
            background-color: $color-primary-5;
            border-radius: 10px;
        }

        .tab-content-padding.container + .tab-content-padding.container {
            padding-top: 0 !important;
        }

        .tab-content-padding {
            padding: 12px 20px;
            @media only screen and (max-width: $breakpoint-sm) {
                padding: 12px 6px;
            }

            &.container {
                :not(:last-of-type(p)) {
                    margin-bottom: 48px; 
                    font-family: Mikadobold;
                }

                p a {
                    color: $color-primary-2;
                    font-size: 1rem;
                    line-height: 1.25;
                    font-weight: 500;
                    text-decoration: underline;
                    transition: opacity 250ms;
                    cursor: pointer;
                }
            }

            &.section-heading {
                .risk-heading {
                    margin-bottom: 0 !important;
                }

                p {
                    margin-bottom: 48px; 
                    font-family: Mikadobold;
                }
            }
        }
    }


    nav {
        //background-color: $color-silver;
        &.stick {
            position: sticky;
            top: -50px;
            left: 0;
            right: 0;
            z-index: 10;
        }

        @media only screen and (min-width: $breakpoint-l) {
            &.stick {
                left: 44%;
                top: -50px;
            }
        }

        .nav-tabs .nav-link.active{
            color: white;
            border-color: #dee2e6 #dee2e6 #fff;
            //font-size: 11px;
            //font-weight: 700;
            font-family: 'Mikadobold';
            background-color: $color-secondary;
            text-decoration: none;
        }
    
        .nav-tabs .nav-link {
            border: 0px solid transparent!important;
            margin: 4px;
            //font-size: 11px;
            //font-weight: 700;
            color: $color-secondary-3;
            font-family: 'Mikado';
            border-radius: 20px;
            background-color: #DADADA;
            text-decoration: underline;
        }
    
        .nav-tabs-outer {
            //background-color: $color-silver;
            padding: 8px 40px;
            display: inline-block;
            @media only screen and (max-width: $breakpoint-sm) {
                padding: 2px;
            }

        }
    
        .nav-tabs {
            border-radius: 20px; 
            background-color: #DADADA;
            flex-wrap: nowrap!important;
        }
    
        .nav-tabs .nav-link:hover, .nav-tabs .nav-link:focus {
            opacity: 0.8;
            transition: 0.3s;
        }
    }

    // p {
    //      font-size: 15px;
    //      line-height: 19.5px;
    //      font-weight: 500;
    // }
    & > .view-in-moodle-container {
        margin-top: 2rem;
        margin-left: 14%;
        display: flex;
        justify-items: center;
        flex-direction: column;
        align-items: center;
        margin-bottom: 2rem;
    
        & > a.view-in-moodle{
            width:fit-content;
            padding:10px 20px;
            background-color:$color-sunny-banana-100;
            color:$color-juicy-grape-100;
            &:hover {
                color:$color-cool-sky-100;
            }
        }
    }
    .joinFormSuccessMessage {
        // final message after submitting the signup form, Welcome to the Program...
        display: flex;
        align-items: center;
        margin-top: 2rem;
        & > svg {
            width: 200px;
            & > path {
                fill:teal
            }
        }
        & #moodle2url {
            width:fit-content;
            margin-left: auto;
            margin-right: auto;
            margin-top: 2rem;
            padding: 10px 42px;
        }
    }
    .container-link {
        padding: 0 !important;
        word-break: break-all;

        a {
            color: #212529;
            font-size: 1rem;
            line-height: 1.125;
            font-weight: 500;
            text-decoration: underline;
            transition: opacity 250ms;
            cursor: pointer;

            &:after {
                content: '',
                // background: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABUAAAAVCAYAAACpF6WWAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAKZSURBVHgBlVQ7ixNRFL53Hpv3PHYNJoWQIgFJp6mFoIIP1kcTG/+AhVhYaSGkEEXdHyKk8S+IWAti0i7YhDR5kEehO3P9zsy5s+OYuOyFjzP39d3vPOYIcf7xDVApHANSnHcopSRfNGzbvgp7HfiUIjZ4PyK3tnA8BLz0gpSxEBB+b7VaP0aj0StMHwBfgGtMSuRhlkyyIu1SFp+Bi8BrPvMOOOTvAr0JmOA4JST0ej2TD90E9oBcu92OLF/UhEelUqkGewP4CTjNZjPHpDIdM5OJ6NItoASUU1YTfgAuA4+AS8VisQ7rA8VOp2MnpP1+32D5Bb546DjOPuw+x/ctr79nhV+B50QK1DQpc0giE8PhMMqsOE0cuWGw+sfAC8MwSOHRer0WTEaPnJTL5QA24LnQVjIZqXR4kSrgAnCAjF+BfYrLVVZV4zg+4zMeq9xjYbFSxEKmEqZfC13XDeHucaVS+bhardJ7gtX9An6TYiQ54PWkvkS9Xpe+7+t1he9gsViE8/k8NE3zRMQ1GLDVNRk0Go1obTAYqDjnKnHfzrj/ErgL3AFu8/c94L6Ii34OPNFuc6KTYbH7YjKZiM1mI6bTKcXrjTh7LP63GSmF+/SqK+LgUyn5rJzqtIRwuLx2QGcQb5/3dIKSkUzG47FKryM5Bu9TWZmz2UyXXZpANxSRJVXdblch4OkDxnK5JGIitDQxeYXSiqoATUbBO+2p+kdptVpV+N0om6HneTrTIYgjAvxdEg8QBEor2kNVBLlcLhRbOlPsA/5XqCVFeTxA/7lLMeQ4upm5w2cKHM+/OtO2UFjcbfIiLpciJ7DA8wLClCcwoZU0kB1DJ8KkbsOxJGvzXMNir4xdhDIbhmgRnZ5c0h1/x9jp8x+sTNUSEkkJEQAAAABJRU5ErkJggg==');
            }

            &:hover {
                opacity: 0.8;
            }
        }
    }
    .suggested-link {
        margin-top: 22px;
        & > button {
            margin-left: auto;
            margin-right: auto;
            width: fit-content;
            padding: 10px 20px;
        }
        
        a {
            font-weight: 600;
            font-size: 1rem;
            line-height: 1;
            color: $color-primary-2;
            text-transform: uppercase;
            text-decoration: underline;
        }
    }
}
