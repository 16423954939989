.component-desktop-screen {
    height: 75vh;

    .header-bar {
        background-color: white;
        width: 100%;
        height: 90px;
        padding-left: 75px;

        .mh4l-logo {
            width: 115px;
            height: 100%;
        }
    }

    .panel-wrapper {
        height: 100%;

        .side-panel {
            position: relative;
            overflow: hidden;
            width: 50%;
            transition: width 250ms;
            display: flex;
            align-items: center;
            height: 100%;
    
            .header-image {
                background-image: url("../assets/upright-hand.svg");        
                background-repeat: no-repeat;
                background-position: center;
                height: 200px;
                position: relative;
                z-index: 4;
            }
    
            .mh4l-logo {
                height: 110px;
            }
    
            .background {
                height: 100%;
                width: 100%;
                position: absolute;
                z-index: 1;
            }
    
            .gradient {
                height: 630px;
                width: 630px;
                position: absolute;
                z-index: 2;
    
                background-image: url("../assets/gradient.svg");        
                background-repeat: no-repeat;
                background-position: center;

                top: -100%;
                left: 50%;
                transform: translateX(-50%);
            }
    
            .panel-content {
                position: relative;
                z-index: 3;
                width: 50%;
                .child-content {
                    position: relative;
                    z-index: 3;    
                }
    
                .component-button {
                    width: 300px;
                    position: relative;
                    z-index: 4;
                }
            }
    
            &.expanded {
                width: 100%;

                .gradient {
                    height: 962px;
                    width: 962px;
                    top: -50%;
                }
            }
        }
    }

    .desktop-main-content {
        width: 50%;
    }
}