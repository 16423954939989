.component-program-sign-up {
    #joinButton {
        max-width:fit-content;
        padding-left:1.5rem;
        padding-right:1.5rem;
    }
    #programSignUpForm{
        input.simple-input {
            margin-bottom:0px;
        }
        p.text-error {
            margin-bottom:6px;
            font-size:1rem;
            color:red;
        }
    }
}
label.receiveNewsletter {
    text-align:left;
    & > span {
        vertical-align:sub;
    }
}
input[type=checkbox].receiveNewsletter {
    margin-right:1rem;
    &::after {
        border:1.5px solid #9F9F9F;
    }
}