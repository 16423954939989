.partial-referral-banner {
	background-color: $color-primary-1;
    width: 100%;
    padding: 10px 0;

	

	.container-flex {
		display: flex;
		padding: 0 75px;
		align-items: center;

		@media screen and (max-width: $breakpoint-sm) {
			padding: 0 20px;
		}

		@media screen and (max-width: 550px) {
			flex-direction: column;
		}

		.container-button {
			.component-button {
				padding: 0 50px;

				@media screen and (max-width: 550px) {
					box-sizing: border-box;
					width: 100%;
				}
			}

			@media screen and (max-width: 550px) {
				padding-top: 10px;
				width: 100%;
			}
		}

		.container-fields {
			display: block;
			flex-grow: 1;

			.banner-row {
				display: block;

				.banner-cell {
					display: inline-block;
					width: 49%;
					text-align: left;
					vertical-align: top;
					padding: 0 20px 0 0;

					@media screen and (max-width: $breakpoint-sm) {
						width: 100%;
					}

					.label {
						font-weight: bold !important;
						display: inline-block;
						padding-right: 5px;
						text-decoration: underline;
					}

					.value {
						color: #444;
						display: inline-block;
					}
				}
			}
		}
	}
}
