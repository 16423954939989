.component-multi-choice {
    margin-bottom: 1rem;

    .text {
        &.text-error {
            margin-left: 0 !important
        }
    }
    
    .multi-choice-row {
        width: 100%;
        max-width: 360px;
        display: block;

        @media screen and (min-width: $breakpoint-l) {
            // rem out - Andrew
            // margin: 0 auto 16px 70px !important;
            margin: 0 auto 16px 0px !important;
        }

        /*  rem out - Andrew   
        .radio_input {
            display: none;
        } */
        .component-form-UI {
            display:flex;
            align-items:center;
        }        

        label {
            margin-left: 2rem;
            text-align: left;
        }
        input[type="text"] {
            border-radius: 6px;
            width: 100%;
            max-width: 306px;
            margin-bottom: 9px;
            outline: none;
            border: none;
            background: transparent;
            border: 1.5px solid $color-slate;
            padding: 6px 18px;
            font-size: 1rem;
            line-height: 1.125;

            &:focus {
                border-color: $color-primary-2 !important;
            }

            &.required {
                border: 2px solid $color-required;

                &::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
                    color: $color-required;
                }
                
                &:-ms-input-placeholder { /* Internet Explorer 10-11 */
                    color: $color-required;
                }
                
                &::-ms-input-placeholder { /* Microsoft Edge */
                    color:$color-required;
                }
            }
        }
        .waist-selection {
            margin-top:1.5rem;
            position: relative !important;
        }
    }

    .single-select-waist {
        margin-top: 16px;
    }
    
    @media screen and (min-width: $breakpoint-sm) {
        &.condense {
            display: flex;
            flex-direction: column;
            flex-wrap: wrap;
    
            >.multi-choice-row {
                display:flex;
                // margin-bottom: 10px;
            }
        }   
    }
    >.multi-choice-row {
        display:flex;
        margin-bottom: 1rem;
        //align-items: center;
    }
}

.waist-selection {
    text-align: left;

    @media screen and (max-width: $breakpoint-l) {
        bottom: 0;
    }

    @media screen and (min-width: $breakpoint-l) {
       // position: absolute;
    }
    
    .modal-dialog {
        border-radius: 16px !important;
    }

    .waist-selection-body {
        .table {
            width: 100%;
            border: 4px solid $color-primary;
            border-radius: 8px;
            overflow: hidden;
            background: $color-primary;
            margin: 0;
            transition: background 250ms;

            .tr {
                display: block;
                border: none;
                margin: -1px;
                padding: 8px 0px;

                .th {
                    font-family: MikadoBold;
                    font-weight: 700;
                    font-size: 1rem;
                    border: none;
                    border-left: 4px solid $color-primary;
                    display: inline-block;
                    margin: -1px;
                    width: 33%;
                    box-sizing: border-box;
                    text-align: center;
                    transition: border 250ms;

                     &:first-child {
                        border-left: none;
                    }
                }

                .td {
                    font-family: LucidaGrande;
                    font-size: 1rem;
                    border: none;
                    border-left: 4px solid $color-primary;
                    display: inline-block;
                    width: 33%;
                    box-sizing: border-box;
                    margin: -1px;
                    text-align: center;
                    transition: border 250ms;

                    &:first-child {
                        border-left: none;
                    }
                }

                &:nth-child(even) {
                    background: white;
                }

                &:nth-child(odd) {
                    background: #f7f1ee;
                }

                &:first-child {
                    background: $color-primary;
                }

                &:hover {
                    cursor: pointer;
                    background: $color-primary-5;

                    .th,
                    .td {
                        border-left: 4px solid $color-primary-5;

                        &:first-child {
                            border-left: none;
                        }
                    }
                }
            }

            &.quarters {
                .th,
                .td {
                    width: 25% !important;
                }
            }
        }
    }

    .waist-selection-footer {
        display: block !important;
        border: none;
        padding-top: 0;

        .component-button {
            margin: 0 auto !important;
            max-width: 100px;
        }
    }
}

.waist-question {
    // padding-left: 0;
    @media screen and (max-width: $breakpoint-l) {
        //padding-left: 80px;
        margin-left: 0;

        .component-button {
            margin-left: 0 !important;
        }
    }

    // @media screen and (max-width: $breakpoint-sm) {
    //     padding-left: 61px;
    //}
}