.component-multiquestion {

    .text {
        &.text-error {
            margin-left: 0 !important
        }
    }
    
    .partial-input {
        display: block;
        justify-content: left;
        align-items: left;
        margin: 20px 0 0 0px;
        
        h4,
        p {
            text-align: left;
            margin-bottom: 1rem;

            a {
               font-family: Mikadobold !important;
               font-size: var(--bs-body-font-size);
               color: $color-primary-2;
               text-decoration: underline;
            }
        }

        .component-yes-no {
            margin: 0 auto 0 0;
        }

        @media screen and (min-width: $breakpoint-sm) {
            margin-left: 80px
        }

        &.component-dynamic-input {
            margin-top: 16px;
        }

        span {
            margin: 0 10px;
        }

        .seperator {
            font-size: 1.375rem;
            font-weight: bold;
        }
        
        input {
            border-radius: 6px;
            width: 100%;
            //margin-bottom: 9px;
            outline: none;
            border: none;
            background: transparent;
            border: 1.5px solid $color-slate;
            padding: 6px 18px;
            // rem out - Andrew - font shoudl scale
            //font-size: 13px;
            //line-height: 18px;
            line-height: 1.1rem;


            &:focus {
                border-color: $color-cool-sky-100;
                
                &:after {
                    border-color: $color-cool-sky-100;
                    border-width: 2px;
                }
            }
            &:focus-within {
                border-color: $color-juicy-grape-100;
                border-width: 2px;
            }

            &.required {
                border: 2px solid $color-required;

                &::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
                    color: $color-required;
                }
                
                &:-ms-input-placeholder { /* Internet Explorer 10-11 */
                    color: $color-required;
                }
                
                &::-ms-input-placeholder { /* Microsoft Edge */
                    color:$color-required;
                }
            }

            max-width: 306px;
            -moz-appearance: textfield;
            appearance: textfield;

            &::-webkit-outer-spin-button,
            &::input::-webkit-inner-spin-button {
                -webkit-appearance: none;
                margin: 0;
            }
        
            &[type=checkbox] {
                -webkit-appearance: none;
                -moz-appearance: none;
                -o-appearance: none;
                appearance: none;
                padding: 0 2px 0 0;
                width:auto;
                border-style:none;
                &:focus:after {
                    outline-width: 3px;
                    outline-style: solid;
                    outline-color: $color-cool-sky-100;
                }
            }
            &[type=radio] {
                -webkit-appearance: none;
                -moz-appearance: none;
                -o-appearance: none;
                appearance: none;
                height: 0.7rem;
                padding: 0 2px 0 0;
                width:auto;
                border-style:none;
                &:focus-within{
                    border-color:$color-cool-sky-100;
                }            
                &:after {
                    content: '';
                    display:block;
                    height: 1.3rem;
                    width: 1.3rem;
                    /* background-color: navy; */
                    position: absolute;
                    border-radius: 50%;
                    border-width: 2px;
                    border-color: teal;
                    border-style: solid;
                }
                &:focus:after {
                    outline-width: 3px;
                    outline-style: solid;
                    outline-color: $color-cool-sky-100;
                }
            }
        }   
    }
}