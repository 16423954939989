// Font Sizes
$font-size-anchor: 1rem;
$font-size-button: 1rem;
$font-size-regular: 1rem;
$font-size-header: 1.25rem;

//Font Weights
$font-weight-bold: 700;
$font-weight-regular: 500;

// Fonts
@font-face {
    font-family: LucidaGrande;
    src: url('../../public/assets/LucidaGrande.woff');
}

@font-face {
    font-family: Mikado;
    src: url('../../public/assets/MikadoRegular.otf') format('opentype');
}

@font-face {
    font-family: MikadoBold;
    src: url('../../public/assets/MikadoBold.otf') format('opentype');
}

// Urls
$arrow-url-black: url("data:image/svg+xml,%3Csvg class='arrow-url-black' viewBox='0 0 7 11' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M6.24265 9.48529L2 5.24265L6.24265 1.00001' stroke='black' stroke-width='2'/%3E%3C/svg%3E%0A");            
$arrow-url-white: url("data:image/svg+xml,%3Csvg class='arrow-url-white' viewBox='0 0 7 11' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M6.24265 9.48529L2 5.24265L6.24265 1.00001' stroke='white' stroke-width='2'/%3E%3C/svg%3E%0A");            

.typography {
    // General
    h1, h2, h3, h4 {
        font-family: MikadoBold;
        font-weight: $font-weight-bold;
    }

    //Desktop
    h1 {
        font-size: 4rem;
        line-height: 80px;
    }

    h2 {
        font-size: 3.5rem;
        line-height: 58px;
    }

    h3 {
        font-size: 2.5rem;
        line-height: 56px;
    }

    h4 {
        font-size: 1.25rem;
        line-height: 26px;
    }

    a {
        font-family: LucidaGrande;
        font-size: 1rem;
        line-height: 2rem;
        font-weight: $font-weight-regular;
        text-decoration: none;

        &.large {
            font-size: 1rem;
            font-weight: 600;
            text-decoration: underline;
        }
    }

    .text {
        font-family: 'LucidaGrande';
        font-weight: $font-weight-regular;
        line-height: 2rem;
        font-size: 1rem;

        &.large {
            font-size: 1rem;
        }

        &.mini {
            font-size: 0.8rem;
        }
    }

    //Landscape Tablet
    @media only screen and (min-device-width : 768px) 
    and (max-device-width : 1280px) 
    and (orientation : landscape) {
        h1 {
            font-size: 2.5rem;
            line-height: 3.5rem;
        }
    
        h2 {
            font-size: 2rem;
            line-height: 4rem;
        }
    
        h3 {
            font-size: 1.25rem;
            line-height: 2rem;
        }
    
        h4, .h4 {
            font-size: 1.125rem;
            line-height: 2rem;
        }

        a {
            line-height: 26px;

            &.large {
                font-size: 0.75rem;
                line-height: 2rem;
            }
        }

        .text {
            line-height: 1.625rem;
            font-size: 1rem;

            &.mini {
                font-size: 0.8rem;
            }
        }
    }

    //Mobile Devices
    @media only screen and (max-width: 768px) {

        h1 {
            font-size: 1.875rem;
            line-height: 2.8125rem;
        }
    
        h2 {
            font-size: 1.625rem;
            line-height: 2.4375rem;
        }

        h3{
            font-size: 1.375rem;
            line-height: 2.0475rem;
        } 

        h4 {
            font-size: 1.125rem;
            line-height: 1.6875;
        }

        a {
            line-height: 26px;

            &.large {
                font-size: 1rem;
                line-height: 2;
            }
        }

        .text {
            line-height: 1.625;
            font-size: 1rem;

            &.mini {
                font-size: 0.8rem;
            }
        }
    }
}

.chat-heading,
.chat-text {
    i, span, strong, b, em, a {
        padding-right: 6px;
    }

    a {
        padding-left: 6px;
        padding-right: 6px;
        word-wrap: break-word;
        color: $color-primary-2;
        font-size: inherit ;
        font-family: MikadoBold;
        text-decoration: underline;
    }
}
