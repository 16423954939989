.partial-mobile-loading-screen {
   // max-width: 450px;
   // margin: 0 auto;
   // position: absolute;
   // left: 50%;
   // transform: translateX(-50%);
   // height: 100%;

/*     @media screen and (max-width: 450px) {
        max-width: 100%;
    } */

    .copy {
        h2 {

        }

        h3 {
            line-height: 1.5 !important;
           // max-width: 320px;
            margin: 0 auto 20px;
            font-size: 1rem !important;
        }

        p {

        }
    }

    .component-slider {

    }

    .component-button {
        max-width: 310px !important;
    }
}