$transition-init-time: 1.2s;
$transition-content-time: 1.2s;
// rem out - Andrew
// $half-width: 45%;
$half-width: 100%;

.partial-desktop-sidebar {
	// height: 75vh;
	// height: 100vh;
	height: 100%;

	min-height: 550px;
	//position: relative;
    //overflow: hidden;
    width: 100%;
	// rem out - Andrew
    // display: block;
	display:none;

    @media screen and (max-width: $breakpoint-l) {
        display: none;
    }

	&:not(.half-width) .content {
		margin: 0 auto;
		padding: 50px 0 150px;
		//overflow-y: auto;
	}

    &.half-width {
    	width: $half-width;

    	animation-name: transition-init-desktop-health-check;
	    animation-duration: $transition-init-time;
	    animation-iteration-count: 1;

	    .container-content {
	    	animation-name: transition-init-desktop-health-check-content;
	    	animation-duration: $transition-init-time;
	    	animation-iteration-count: 1;
	    }

	    .background-gradient {
	    	animation-name: transition-init-desktop-health-check-content;
	    	animation-duration: $transition-init-time;
	    	animation-iteration-count: 1;
	    }

	    .container-content {
    		.content {
    			.graphic {
    				animation-name: transition-init-desktop-health-check-hide-forever;
			    	animation-duration: $transition-init-time;
			    	animation-iteration-count: 1;
			    	animation-fill-mode: forwards;
    			}
    			
    			.primary.regular {
    				animation-name: transition-init-desktop-health-check-hide-forever;
			    	animation-duration: $transition-init-time;
			    	animation-iteration-count: 1;
			    	animation-fill-mode: forwards;
    			}
    		}
    	}
    }

    &.transition-content {
    	animation-name: transition-desktop-health-check-content;
    	animation-duration: $transition-content-time;
    	animation-iteration-count: 1;
    	animation-fill-mode: forwards;
    }



	.background-pattern {
		height: 100%;
        width: 100%;
        position: absolute;
        z-index: 10;
        background-repeat: repeat;
        // background-size: 1110px 605px;
	}

	.background-gradient {
		// rem out - Andrew
		//height: 630px;
        height: 100%;
        width: 100%;
        position: absolute;
        z-index: 20;

        background-image: url("../assets/gradient.svg");        
        background-repeat: no-repeat;
        background-position: center;

        top: 50%;
        left: 50%;
        margin: -315px 0 0 -315px;
	}

	.container-content {
		position: relative;
		z-index: 30;
		height: 100%;
		width: 100%;
		display: flex;
		align-items: center;
		// transition: opacity 250ms

		.content {
			margin: 0 auto;
			padding: 50px 0 150px;
			// overflow-y: auto;
			height: 100%;
			width: 100%;
			
			.graphic {
				background-image: url("../assets/mh4l-logo-full.png");        
                background-repeat: no-repeat;
                background-position: center;
                height: 200px;
                position: relative;
			}

			.copy {
				color: white;

				p {
					max-width: 600px;
					margin-left: auto;
					margin-right: auto;
					line-height: 28px;
					color:black;
				}
			}

			.primary.regular { // button
				max-width: 300px;
			}
		}
	}
}

@keyframes transition-init-desktop-health-check {
  0% {width: 100%;}
  25% {width: 100%;}
  50% {width: $half-width;}
  75% {width: $half-width;}
  100% {width: $half-width;}
}

@keyframes transition-init-desktop-health-check-content {
  0% {opacity: 1;}
  25% {opacity: 0;}
  50% {opacity: 0;}
  75% {opacity: 1;}
  100% {opacity: 1;}
}

@keyframes transition-desktop-health-check-content {
	0% {opacity: 1;}
	50% {opacity: 0;}
	100% {opacity: 1;}
}

@keyframes transition-init-desktop-health-check-hide-forever {
  0% {
  	opacity: 1;
  }
  25% {
  	opacity: 0;
  }
  50% {
  	opacity: 0;
  	height: 0;
  	width: 0;
  	pointer-events: none;
  	display: none;
  }
  75% {
  	opacity: 0;
  	height: 0;
  	width: 0;
  	pointer-events: none;
  	display: none;
  }
  100% {
  	opacity: 0;
  	height: 0;
  	width: 0;
  	pointer-events: none;
  	display: none;
  }
}