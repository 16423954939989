.component-form-UI{
    .start-padding{
        padding-top: 40px;
        padding-bottom: 40px;
    }
    .submit-button{
        width: 100%;
        background-color: $color-primary;
        border-radius: 50px;
        font-size: $font-size-button;
        font-family: Mikadobold;
        margin-top: 20px;
    }
    .btn-lg{
        padding: 1rem 1rem!important;
    }
    .submit-button:hover {
        border-color: transparent;
        box-shadow: none;
        transition: 0.3s;
        background-color: $color-primary-hover;
        
    }
    .to-center{
        justify-content: center;
    }
    .to-input{
        min-width: 240px;
        border-radius: 6px;
        font-size: 1rem;
        font-family: LucidaGrande;
        background-color: $color-primary;
        &.transparent-background{
            background-color:transparent;
            border: 1.5px solid #9F9F9F;
        }
        &.colored-background{
            border: 1.5px solid $color-primary-2;
            background-color: $color-primary;
        }
    }
    .transparent-background:focus{
        border: 1.5px solid $color-primary-2;
        box-shadow: none;
    }
    .colored-background:focus{
        border: 1.5px solid $color-primary-2;
        box-shadow: none;
    }
    .contact-h-p{
        text-align: left;
        h1{
            font-size: 24px!important;
            padding-bottom: 20px;
            color:#434243
        }
        p{
            font-size: 1rem;
            color: #434243;
            font-family: LucidaGrande;
        }
    }

    label {
        display: block;
    }

    .simple-input {
        border-radius: 6px;
        width: 100%;
        margin-bottom: 9px;
        outline: none;
        border: none;
        background: transparent;
        border: 1.5px solid $color-slate;
        padding: 6px 18px;
        font-size: 1rem;
        line-height: 1.125;

        &:focus {
            border-color: $color-primary-2 !important;
        }

        &.required {
            border: 1.5px solid $color-required;

            &::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
                color: $color-required;
            }
            
            &:-ms-input-placeholder { /* Internet Explorer 10-11 */
                color: $color-required;
            }
            
            &::-ms-input-placeholder { /* Microsoft Edge */
                color:$color-required;
            }
        }
    }

    input[type="submit"] {
        width: fit-content;
        padding-left:20px;
        padding-right:20px;
        font-size: 1rem;
        line-height: 1.125;
        font-weight: 700;
        font-family: Mikadobold;
        letter-spacing: 1px;
        margin-top:20px;
        margin-bottom:20px;
    }

    .consent-section {
        width: 90%;
        margin: 20px 0 32px 0;

        label {
            display: flex;
            margin-top: 16px;

            span {
                text-align: left;
                margin-left: 32px;
                font-weight: 500;
                font-size: 1rem;
                line-height: 1.125;
                color: $color-primary-2;
            }
        }

        a {
            color: $color-primary-2;
            text-decoration: underline;
        }
    }
    input[type="radio"] {
        height: 18px;
        -webkit-appearance: none;
        -moz-appearance: none;
        -o-appearance: none;
        appearance: none;
        padding: 0 2px 0 0;
        width:auto;
        border-style:none;
        position: relative;

        &.required {
            &::after {
                border: 1.5px solid $color-required;
            }
        }

       &::after {
            content: "";
            height: 21px;
            width: 21px;
            position: absolute;
            top: -1px;
            left: -1px;
            border: 1.5px solid $color-primary-2;
            border-radius: 6px;
            background-color: $color-primary;
       }

        &:checked {
            &::after {
                background-color: $color-primary-2;
            }
        }
    
    }

    input[type="checkbox"] {
       width: 18px; 
       height: 12px; 
    //   pointer-events: none; 
       position: relative;
    //    border-radius: 6px;
    //    border: 1.5px solid $color-primary-2;

        &.required {
            &::after {
                border: 1.5px solid $color-required;
            }
        }

       &::after {
            content: "";
            height: 21px;
            width: 21px;
            position: absolute;
            top: -1px;
            left: -1px;
            border: 1.5px solid $color-primary-2;
            border-radius: 6px;
            background-color: $color-primary;
       }

        &:checked {
            &::after {
                background-color: $color-primary-2;
            }
        }
    }

    .form-submission-message {
        display: none;
        margin-top: 20px;

        &.active {
            display: block;
        }
    }

    .loader-wrapper {
        &.disabled {
            display: none;
        }

        width: 150px;
        background-color: #f6eee8;
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
    }
    .submission-message-a{
        font-size: 1rem;
        line-height: 1.125;
        font-weight: 500;
        color: #4F2579;
        :hover{
            color: #725194 !important;
        }
    }
}
