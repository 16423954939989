.component-dynamic-yes-no-skip {
    .column {
        flex-direction: column;

        .component-button {
            width: 100% !important;
            max-width: 232px !important;
            margin-bottom: 8px;

            &:last-of-type {
                margin-bottom: 0px;
            }
        }
    }

    .component-button {
        height: 40px;
        max-width: 50%;

        .inner-text {
            display: flex;
            justify-content: center;
            white-space: nowrap;
        }
    }
}