// Breakpoints
$breakpoint-l: 1024px;
$breakpoint-sm: 768px;
$breakpoint-xs: 576px;

// Colours - Default
$color-black: #000000;
$color-white: #FFFFFF;
$color-light-blue: #DBECED;
$color-light-purp: #C6B6D6;
$color-light-blue-shadow: rgba(155,131,180,0.75);
$color-silver: #EFEFEF;
$color-slate: #9F9F9F;
$color-required: #CE4B4B;

// Colours - Primary
$color-primary: #F6EEE8;
$color-primary-1 : #F6EEE8;
$color-primary-2: #5B217C;
// $color-primary-3:#FFDE3E; // legacy color decom20230309 due to incompatibility with color compression
$color-primary-3:#ffe400;
$color-primary-4: #FFDD00;
$color-primary-5: #D4F1F2;

$color-primary-hover: #fffefe;
$color-primary-2-hover: rgb(135, 108, 161);
$color-primary-3-hover:#faeb63;

// Colours - Secondary
$color-secondary: #2BB8C8;
$color-secondary-2: #725194;
$color-secondary-3: #727272;
$color-secondary-4: #F4F4F4;

$color-secondary-hover: #55d4da;
$color-secondary-2-hover: #725194;

// Colours - Results
$color-results-1:#7FC68D;
$color-results-2:#FFCF36;
$color-results-3:#F9A948;
$color-results-4:#E78080;
$color-results-5:#CE4B4B;

// Colours - Tab
$color-tab-primary: $color-silver;
$color-tab-secondary: $color-slate;

// Colours from Branding Style Guide -- Andrew
$color-cool-sky-100:#2BB8C8;
$color-juicy-grape-100:#5B217C;
$color-sunny-banana-100:#FFE400;
$color-black-70:#6F6F6F;
$color-warm-white-100:#F6EEE8;

// Font Sizes
// $font-size-anchor: 11px;
// $font-size-button: 12px;
// $font-size-regular: 15px;
// $font-size-header: 20px;

//Font Weights
$font-weight-bold: 700;

// Urls
$arrow-url-black: url("data:image/svg+xml,%3Csvg viewBox='0 0 10 10' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M0.958984 8.31224L7.14617 2.12506L13.3334 8.31224' stroke='black' stroke-width='2' %3E%3C/svg%3E");            
$arrow-url-white: url("data:image/svg+xml,%3Csvg viewBox='0 0 10 10' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M0.958984 8.31224L7.14617 2.12506L13.3334 8.31224' stroke='white' stroke-width='2' %3E%3C/svg%3E");            
$icon-info: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0naHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmcnIGhlaWdodD0nMTYwJyB3aWR0aD0nMTYwJyB2ZXJzaW9uPScxLjAnPjxnIGZpbGw9JyMyZjU3YjMnPjxwYXRoIGQ9J204MCAxNWMtMzUuODggMC02NSAyOS4xMi02NSA2NXMyOS4xMiA2NSA2NSA2NSA2NS0yOS4xMiA2NS02NS0yOS4xMi02NS02NS02NXptMCAxMGMzMC4zNiAwIDU1IDI0LjY0IDU1IDU1cy0yNC42NCA1NS01NSA1NS01NS0yNC42NC01NS01NSAyNC42NC01NSA1NS01NXonLz48cGF0aCBkPSdtNTcuMzczIDE4LjIzMWE5LjM4MzQgOS4xMTUzIDAgMSAxIC0xOC43NjcgMCA5LjM4MzQgOS4xMTUzIDAgMSAxIDE4Ljc2NyAweicgdHJhbnNmb3JtPSdtYXRyaXgoMS4xOTg5IDAgMCAxLjIzNDIgMjEuMjE0IDI4Ljc1KScvPjxwYXRoIGQ9J205MC42NjUgMTEwLjk2Yy0wLjA2OSAyLjczIDEuMjExIDMuNSA0LjMyNyAzLjgybDUuMDA4IDAuMXY1LjEyaC0zOS4wNzN2LTUuMTJsNS41MDMtMC4xYzMuMjkxLTAuMSA0LjA4Mi0xLjM4IDQuMzI3LTMuODJ2LTMwLjgxM2MwLjAzNS00Ljg3OS02LjI5Ni00LjExMy0xMC43NTctMy45Njh2LTUuMDc0bDMwLjY2NS0xLjEwNScvPjwvZz48L3N2Zz4K");
// Fixed heights
$header-height: 132px;